import { useQuery } from "@apollo/client";
import { i18n } from "@lingui/core";
import Delegate from "../assets/imgs/Delegate.png";
import { utils, constants } from "ethers";
import { gql } from "graphql-tag";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectedDelegateReactive } from "../apollo";
import GradientAvatar from "../assets/imgs/Gradient.svg";
import GreenTick from "../assets/imgs/GreenTick.svg";
import SpeechBubble from "../assets/imgs/SpeechBubble.svg";
import { CTAButton } from "../components/buttons";
import EtherscanBox from "../components/EtherscanBox";
import Footer from "../components/Footer";
import Gap from "../components/Gap";
import { ContentBox, NarrowColumn } from "../components/layout";
import LazyImage from "../components/LazyImage";
import Loader from "../components/Loader";
import {
  DelegateProfile,
  SelectedDelegateProfile,
  AvatarImg as _AvatarImg,
} from "../components/Profile";
import { Content, Header, SubTitle } from "../components/text";
import { emptyAddress } from "../utils/consts";
import {
  useGetDelegateBySigStatus,
  useGetDelegatedTo,
  useGetTokens,
  useGetCurrentVotes,
  useGetTransactionDone,
  useGetCurrentVotesHook,
  useDelegateVotes,
} from "../utils/hooks";
import { largerThan, smallerThan } from "../utils/styledComponents";
import {
  formatTokenAmount,
  imageUrl,
  numberWithCommas,
  shortenAddress,
} from "../utils/utils";
import { initWeb3 } from "../web3modal";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";
import PurpleIconURI from "../assets/imgs/PurpleIcon.svg";
import EnteryourDelegate from "./EnteryourDelegatePopup";
import TokenDelegation from "../components/TokenDelgation";

const DELEGATE_RANKING_QUERY = gql`
  query delegateRankingQuery @client {
    addressDetails
    isConnected
    address
    delegates
    tokensOwned
    delegatedTo
    currentVotes
    delegateSigDetails
    selectedDelegate
  }
`;

const DelegateBoxContainer = styled.div`
  border: 1px solid ${(p) => (p.selected ? "rgba(73, 179, 147, 1)" : "#363636")};
  box-sizing: border-box;
  border-radius: 16px;
  display: ${(p) => (p.search ? "flex" : "none")};
  height: 80px;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  cursor: ${({ alreadyDelegated }) =>
    alreadyDelegated ? "default" : "pointer"};
  transition: all 0.33s cubic-bezier(0.83, 0, 0.17, 1);
  position: relative;

  ${({ alreadyDelegated }) =>
    alreadyDelegated &&
    `
      background-color: rgba(0, 0, 0, 0.08);
    `}

  ${(p) =>
    p.account
      ? `
      &:hover {
        border: 1px solid
          ${(p) => (p.selected ? "rgba(73, 179, 147, 1)" : "#5298FF")};
      }
  `
      : ""}
`;

const AvatarImg = styled.img`
  background: #9f71ff;
  border-radius: 50%;
  width: ${(p) => (p.large ? "60px" : "50px")};
  height: ${(p) => (p.large ? "60px" : "50px")};
  margin-right: 10px;
`;

const MidContainer = styled.div`
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
`;

const SpeechBubbleImg = styled.img`
  filter: invert(77%) sepia(33%) saturate(10%) hue-rotate(33deg) brightness(90%)
    contrast(86%);

  &:hover {
    filter: invert(51%) sepia(97%) saturate(1961%) hue-rotate(196deg)
      brightness(103%) contrast(101%);
  }
`;

const SpeechBubbleImgText = styled.img`
  margin: 0 5px;
  filter: invert(77%) sepia(33%) saturate(10%) hue-rotate(33deg) brightness(90%)
    contrast(86%);
`;

const Logo = styled.img`
  position: absolute;
  top: -11px;
  right: -11px;
  width: 23px;
  height: 23px;
`;

const DelegateBoxName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #ffffff;
`;

const ProfileLink = styled.a`
  flex-basis: auto;
`;

const DelegateBoxVotes = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: #989898;
`;

const Gradient = styled.div`
  background-image: url(${PurpleIconURI});
  background-size: cover;
  width: ${(p) => (p.large ? "60px" : "50px")};
  height: ${(p) => (p.large ? "60px" : "50px")};
  border-radius: 50%;
  margin-right: 10px;
`;

const DelegateBox = (data) => {
  const {
    avatar,
    profile,
    votes,
    name,
    address,
    setRenderKey,
    userAccount,
    delegatedTo,
    search,
    selectedDelegate,
    isCustom,
  } = data;

  const selected =
    name === selectedDelegate.name || address === selectedDelegate.address;
  const imageSrc = imageUrl(avatar, name, 1);

  const [isImgError, setIsImgError] = useState(false);

  useEffect(() => {
    setIsImgError(false);
  }, [name]);

  return (
    <DelegateBoxContainer
      key={name || address}
      onClick={() => {
        if (delegatedTo !== address) {
          selectedDelegateReactive({ name, address });
        }
      }}
      alreadyDelegated={delegatedTo === address}
      search={search}
      selected={selected}
      account={userAccount}
    >
      {selected && <Logo src={GreenTick} />}
      <LeftContainer>
        {isImgError || !name ? (
          <_AvatarImg />
        ) : imageSrc ? (
          <LazyImage
            src={imageUrl(avatar, name, 1)}
            onError={(e) => {
              e.target.onerror = null;
              setIsImgError(true);
            }}
            ImageComponent={AvatarImg}
          />
        ) : (
          <Gradient />
        )}
        <MidContainer>
          <DelegateBoxName data-testid="delegate-box-name">
            {name || (address ? shortenAddress(address, 18, 7, 7) : "")}
          </DelegateBoxName>
          <DelegateBoxVotes>
            {numberWithCommas(Math.floor(votes))} votes
          </DelegateBoxVotes>
        </MidContainer>
      </LeftContainer>
      <ProfileLink
        href={profile}
        target={"_blank"}
        onClick={(e) => e.stopPropagation()}
      ></ProfileLink>
    </DelegateBoxContainer>
  );
};

const BlockImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 415px;
  margin: 30px;
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
`;

const WrappedTitle = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 118%;

  text-align: center;
  letter-spacing: -0.01em;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const WrappedSubTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #ffffff;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const WrappedNarrowColumn = styled(NarrowColumn)`
  max-width: 960px;
`;

const DelegatesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 12px;
  grid-column-gap: 14px;
  padding: 10px 30px 30px;
  justify-content: center;
  max-height: calc(100vh / 3);
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 30px 30px 0;

  ${largerThan.mobile`
    flex-direction: row;
    margin-bottom: 20px;
  `}
`;

const WrappedCTAButton = styled(CTAButton)`
  width: 210px;
  cursor: auto;
  ${(p) =>
    p.account &&
    `
    cursor: pointer;
  `}
`;

const CopyContainer = styled.div`
  margin-bottom: 20px;

  ${largerThan.mobile`
     margin-bottom: 0px;
  `}
`;

const SubHeader = styled.div`
  display: grid;
  margin: 0 30px 10px;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  grid-column-gap: 14px;
  ${largerThan.tablet`
    grid-template-columns: 2fr minmax(240px, 290px);
  `}
`;

const CurrentDelegate = styled.div`
  ${largerThan.tablet`
    margin-left: auto;
    margin-right: 60px;
  `}
  ${smallerThan.mobile`
    display: none;
  `}
`;

const Line = styled.div`
  border: 0.1px solid rgb(54, 54, 54);
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 12px;
  margin-bottom: 15px;
`;

const SectionLabel = styled.span`
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const Input = styled.input`
  font-family: inherit;
  height: 64px;
  box-sizing: border-box;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgb(54, 54, 54);
  background: #252525;
  border-radius: 14px;
  padding: 0px 20px;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;

  &::placeholder {
    color: #ffffff;
    opacity: 0.23;
  }

  color: #ffffff;
`;

const Clear = styled("button")`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 10px 16px;
  cursor: pointer;
  margin-left: auto;

  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #63666a;
`;

const CurrentDelegationContainer = styled("div")`
  border: 1px solid rgb(54, 54, 54);
  box-sizing: border-box;
  border-radius: 14px;
  padding: 12px;
  display: flex;
  align-items: center;
  width: 300px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    margin-right: 10px;
    padding: 10px 0;

    color: #bfbfbf;

    strong {
      font-weight: bold;
      color: #ffffff;
    }
  }
`;

const FreeDelegationHeader = styled(Header)`
  font-size: 22px;
  display: inline-block;
`;

const FreeDelegationSubTitle = styled(SubTitle)`
  display: inline-block;
`;

const FreeDelegationContentBox = styled(ContentBox)`
  display: flex;
  padding: 15px 30px;
  flex-direction: row;
  justify-content: space-between;
`;

function CurrentDelegation({
  account,
  tokens,
  selection,
  votes,
  setRenderKey,
  name,
}) {
  let addy = "";

  let text = (
    <>
      {/* <span>
        <Trans>
          You have delegated <strong>{tokens}</strong> votes to
        </Trans>
      </span> */}
      <SelectedDelegateProfile address={""} votes={0} />
    </>
  );

  if (account?.toLowerCase() === selection?.toLowerCase()) {
    text = <SelectedDelegateProfile votes={votes} isSelf />;
  } else if (selection && selection !== constants.AddressZero) {
    text = (
      <>
        {/* <span>
          <Trans>
            You will delegate <strong>{tokens}</strong> votes to
          </Trans>
        </span> */}
        <SelectedDelegateProfile
          address={selection}
          votes={votes}
          name={name}
        />
      </>
    );
  }

  return (
    <CurrentDelegationContainer data-testid="current-delegation">
      {text}
      {/* {selection !== null && (
        <Clear
          onClick={() => {
            selectedDelegateReactive({ name: null, address: null });
          }}
        >
          <Trans>Clear</Trans>
        </Clear>
      )} */}
    </CurrentDelegationContainer>
  );
}

const HOME_QUERY = gql`
  query privateRouteQuery @client {
    addressDetails
    address
  }
`;

const ChooseYourDelegate = () => {
  const { data: chooseData } = useQuery(DELEGATE_RANKING_QUERY);
  useGetTokens(chooseData.address);
  useGetCurrentVotes(chooseData.address);
  useGetDelegatedTo(chooseData.address);
  useGetDelegateBySigStatus(chooseData.address);
  const { delegates, loading: delegatesLoading } = chooseData.delegates;
  const { balance, loading: balanceLoading } = chooseData.tokensOwned;
  const { delegatedTo, loading: delegatedToLoading } = chooseData.delegatedTo;
  const currentVotesDelegatedTo = useGetCurrentVotesHook(delegatedTo);

  const { currentVotes, loading: currentVotesLoading } =
    chooseData.currentVotes;
  const { details: delegateSigDetails, loading: delegateSigDetailsLoading } =
    chooseData.delegateSigDetails;
  const { selectedDelegate } = chooseData;

  const {
    data: { selectedDelegate: selected },
  } = useQuery(gql`
    query privateRouteQuery @client {
      selectedDelegate
    }
  `);

  const {
    data: { customDelegate },
  } = useQuery(gql`
    query privateRouteQuery @client {
      customDelegate
    }
  `);

  const { currentVotes: customVotes } = useDelegateVotes(
    customDelegate?.address
  );

  const history = useHistory();
  const location = useLocation();

  const transactionDone = useGetTransactionDone(
    location.state && location.state.hash
  );

  const [renderKey, setRenderKey] = useState(0);
  const [search, setSearch] = useState("");
  const [showDelegatePopup, setShowDelegatePopup] = useState(false);

  const [step, setStep] = useState(0);

  useEffect(() => {
    logEvent("delegate_ranking_page");
  }, []);

  const balanceFloat = Number(balance ? utils.formatEther(balance) : 0);

  const tokens = balanceFloat.toFixed(0);

  const {
    data: { address },
  } = useQuery(HOME_QUERY);

  useEffect(() => {
    if (!address) {
      setStep(0);
    }
  }, [address]);

  let delegatedToName = "";
  if (chooseData.delegates.delegates.length) {
    const delegated = chooseData.delegates.delegates.find((d) => {
      if (d.address && delegatedTo) {
        return d.address.toLowerCase() === delegatedTo.toLowerCase();
      }
    });
    if (delegated) {
      delegatedToName = delegated.name;
    }
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <EnteryourDelegate
        show={showDelegatePopup}
        setShow={setShowDelegatePopup}
      />
      <WrappedNarrowColumn>
        {!transactionDone && (
          <>
            <EtherscanBox
              message={i18n._("Your transaction is pending")}
              transactionHash={location.state.hash}
            />
            <Gap height={3} />
          </>
        )}
        {/* TODO: look into gas-free delegation */}
        {/* {chooseData?.address && (
      <FreeDelegationContentBox>
        {delegateSigDetails?.next !== undefined ? (
          <Fragment>
            <FreeDelegationHeader>
              {!delegateSigDetails?.canSign &&
              delegateSigDetails?.formattedDate
                ? `You will be eligible for gas free-delegation ${
                    delegateSigDetails?.formattedDate.split(" ")[0]
                  }`
                : "You are eligible for gas free-delegation"}
            </FreeDelegationHeader>
            <FreeDelegationSubTitle>
              {!delegateSigDetails?.canSign &&
              delegateSigDetails?.formattedDate
                ? delegateSigDetails?.formattedDate.split(" ")[1]
                : ""}
            </FreeDelegationSubTitle>
          </Fragment>
        ) : !delegateSigDetailsLoading ? (
          <FreeDelegationHeader>
            You're not eligible for gas-free delegation
          </FreeDelegationHeader>
        ) : (
          <FreeDelegationHeader>
            Checking gas-free delegation eligibility...
          </FreeDelegationHeader>
        )}
      </FreeDelegationContentBox>
    )} */}
        {step === 0 && <TokenDelegation onClick={() => setStep(1)} />}
        {step === 1 && (
          <>
            <Gap height={3} />
            <ContentBox padding={"none"}>
              <HeaderContainer>
                <CopyContainer>
                  <Header>
                    <Trans>Choose a delegate</Trans>
                  </Header>
                  <Gap height={3} />
                  <Content
                    style={{
                      maxWidth: "400px",
                    }}
                  >
                    <Trans>
                      Delegate your{" "}
                      <strong>{numberWithCommas(tokens)} HIFI</strong> to a
                      community member, yourself, or any ENS name or Ethereum
                      address.
                    </Trans>
                  </Content>
                </CopyContainer>

                <CurrentDelegate>
                  <SectionLabel>
                    <Trans>Current Delegate</Trans>
                  </SectionLabel>
                  <div
                    style={{
                      height: "10px",
                    }}
                  />
                  <div>
                    {chooseData?.address &&
                      (balanceLoading ? null : (
                        <CurrentDelegation
                          account={chooseData?.address}
                          tokens={tokens}
                          selection={delegatedTo}
                          votes={currentVotesDelegatedTo}
                          setRenderKey={setRenderKey}
                          name={delegatedToName}
                        />
                      ))}
                  </div>
                </CurrentDelegate>
              </HeaderContainer>
              {/* <SubHeader account={chooseData?.address}>
        <Input
          account={chooseData?.address}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder={"Search..."}
        />
      </SubHeader> */}
              <SectionLabel
                style={{
                  marginLeft: "30px",
                }}
              >
                <Trans>Delegates</Trans>
              </SectionLabel>
              <Line />
              {delegatesLoading ? (
                <Loader center large />
              ) : (
                <DelegatesContainer data-testid="delegates-list-container">
                  {!!customDelegate?.address && (
                    <DelegateBox
                      {...{
                        address: customDelegate.address,
                        name: customDelegate.name,
                        avatar: "https://",
                        profile: "",
                        ranking: 0,
                        votes: customVotes,
                      }}
                      isCustom
                      selectedDelegate={selectedDelegate}
                      key={"Custom Delegate"}
                      userAccount={chooseData.address}
                      delegatedTo={delegatedTo}
                      search={true}
                    />
                  )}
                  <DelegateBox
                    {...{
                      address: chooseData.address,
                      avatar: "",
                      name: i18n._("Self Delegate"),
                      profile: "",
                      votes: currentVotes
                    }}
                    selectedDelegate={selectedDelegate}
                    key={"Self Delegate"}
                    userAccount={chooseData.address}
                    delegatedTo={delegatedTo}
                    search={true}
                  />
                  {delegates.map((d) => (
                    <DelegateBox
                      {...d}
                      selectedDelegate={selectedDelegate}
                      key={d.name}
                      address={d.address}
                      userAccount={chooseData.address}
                      delegatedTo={delegatedTo}
                      search={d.name.includes(search)}
                    />
                  ))}
                </DelegatesContainer>
              )}

              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 30px",
                }}
              >
                <WrappedCTAButton
                  text={<Trans>Set Custom Delegate</Trans>}
                  type={"deny"}
                  onClick={() => {
                    if (chooseData?.address) {
                      // history.push("/manual-delegates-no-swap");
                      setShowDelegatePopup(true);
                    }
                  }}
                  account={chooseData?.address}
                  disabled={chooseData?.address && selectedDelegate.name}
                />
                <WrappedCTAButton
                  text={<Trans>Confirm Delegate</Trans>}
                  type={
                    !selectedDelegate.address ||
                    selectedDelegate.address === delegatedTo
                      ? "disabled"
                      : ""
                  }
                  onClick={() => {
                    history.push("/delegate-tokens-no-swap");
                  }}
                  disabled={
                    !selectedDelegate.address ||
                    selectedDelegate.address === delegatedTo
                  }
                />
              </div>
            </ContentBox>
          </>
        )}
      </WrappedNarrowColumn>
    </div>
  );
};

export default ChooseYourDelegate;
